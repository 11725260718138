import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import TagManager from 'react-gtm-module'

class Tagging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.productDetailsPage = this.productDetailsPage.bind(this);
    this.addObserversForProducts = this.addObserversForProducts.bind(this);
  }

  componentDidMount() {
    this.productDetailsPage();
    this.addObserversForProducts(this.props.sitecoreContext);

    // Load only
    let pageCategory = '';
    if (this.props.sitecoreContext.route.templateName === 'ProductRoute') {
      pageCategory = 'product detail page';
    } else {
      pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
    }

    window.dataLayer.push({
      brand: 'LPB',
      language: 'it',
      country: 'it',
      siteTypeLevel: 'main',
      pageCategory: this.props.sitecoreContext.route.fields.pageCategoryGTM ? this.props.sitecoreContext.route.fields.pageCategoryGTM.value : '',
    });

    // Initialize TagManager once
    const TagManagerArgs = {
      gtmId: 'GTM-K2XZQB2',
    };
    TagManager.initialize(TagManagerArgs);

    // EVENTS

    //EVENT: SOCIAL CONNECTION IN FOOTER & HEADER
    document.querySelectorAll('.link_social .link, .OverHead a').forEach(function (elem) {
      elem.addEventListener('click', function () {
        const url = elem.getAttribute('href');
        let social = '(facebook|twitter|pinterest|instagram|youtube)';
        let position = 'footer';

        if (url.includes('facebook')) {
          social = 'facebook';
        } else if (url.includes('instagram')) {
          social = 'instagram';
        } else if (url.includes('youtube')) {
          social = 'youtube';
        }

        position = elem.classList.contains('helv_rom') ? 'header' : 'footer';

        window.dataLayer.push({
          event: 'uaevent',
          eventCategory: position,
          eventAction: 'social:: ' + social,
          eventLabel: url,
        });
      });
    });

    //EVENT: ERROR 404
    if (this.props.sitecoreContext.route.name === '404') {
      window.dataLayer.push({
        event: 'uaevent',
        eventCategory: 'error',
        eventAction: '404',
        eventLabel: window.location.href,
      });
    }

  }

  addObserversForProducts(context) {
    // PRODUCT IMPRESSIONS
    setTimeout(function () {
      document.querySelectorAll('.product-item').forEach(function (elem) {
        let observer = new IntersectionObserver(
          function (entries) {
            if (entries[0].isIntersecting === true) {
              const name = entries[0].target.getAttribute('data-gtm-name');
              const eanCode = entries[0].target.getAttribute('data-gtm-ean');
              const productCategory = entries[0].target.getAttribute('data-gtm-category');
              const list = entries[0].target.getAttribute('data-gtm-list');
              const position = entries[0].target.getAttribute('data-gtm-position');

              let pageCategory = context.route.fields.pageCategoryGTM.value;
              if (context.route.templateName === 'ProductRoute') pageCategory = 'product detail page';
              else if (context.route.templateName === 'ArticleRoute') pageCategory = 'content page::article';
              else if (context.route.templateName === 'ProductFinderRoute') pageCategory = 'product selector page';

              window.dataLayer.push({
                event: 'nievent',
                eventCategory: 'Ecommerce',
                eventAction: 'Product Impressions',
                eventLabel: pageCategory,
                event_name: 'view_item_list',
                ecommerce: {
                  currencyCode: 'EUR',
                  impressions: [
                    {
                      name: name,
                      id: eanCode,
                      brand: 'LPB',
                      category: productCategory,
                      list: list,
                      position: position,
                    },
                  ],
                },
              });

              observer.unobserve(entries[0].target);
            }
          },
          { threshold: [0.1] }
        );

        observer.observe(elem);
      });
    }, 1000);
  }

  

  

  // PRODUCT DETAILS
  productDetailsPage() {
    if (this.props.sitecoreContext.route.templateName === 'ProductRoute') {
      const name = this.props.sitecoreContext.route.displayName;
      const id = this.props.sitecoreContext.route.itemId;
      const category = this.props.sitecoreContext.route.fields.productCategories[0].name;

      window.dataLayer.push({
        event: 'nievent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Detail',
        eventLabel: `${name}::${id}`,
        event_name: 'view_item',
        actionField: {
          action: 'detail',
        },
        ecommerce: {
          detail: {
            products: [
              {
                name: name,
                id: id,
                brand: 'LPB',
                category: category,
              },
            ],
          },
        },
      });

      // setTimeout(() => {
      //   //hotfix because lpbo it is shit
      //   document.querySelector('.product-excerpt button')?.addEventListener('click', () => handleAddToCart(this.props.sitecoreContext.route.fields.findSalon.value.text));
      // }, 1000);

    }
  }


  updateDataLayerPageview() {
    this.addObserversForProducts(this.props.sitecoreContext);
    this.productDetailsPage();

    let pageCategory = '';
    if (this.props.sitecoreContext.route.templateName === 'ProductRoute') {
      pageCategory = 'product detail page';
    } else {
      pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
    }

    window.dataLayer = window.dataLayer || [];
    var json = {
      event: 'pageChange',
      brand: 'LPB',
      language: 'it',
      country: 'it',
      siteTypeLevel: 'main',
      pageCategory: pageCategory,
    };
    window.dataLayer.push(json);
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) => {
      if (prevProps[key] !== val) {
        if (key == 'pageTitle') {
          this.updateDataLayerPageview();
        }
      }
    });
  }

  render() {
    return <div></div>;
  }
}

export default withSitecoreContext()(Tagging);

export function handleAddToCart(storeName) {
  const name = this.props.sitecoreContext.route.displayName
  const id = this.props.sitecoreContext.route.itemId
  window.dataLayer.push({
    event: 'addToCart',
    event_name: 'add_to_cart',
    eventAction: `redirection::${storeName}`,
    eventCategory: 'Ecommerce',
    eventLabel: `${name}::${id}`,
    product_info: `${name}::${id}`,
    step: 'redirection',
    store_name: storeName,

    ecommerce: {
      add: {
        products: [
          {
            brand: 'LPB',
            category: category,
            id: id,
            name: name,
          },
        ],
      },
    },
  });
}

export function handleSubmitReview(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'submit_review',
    eventCategory: 'product detail page',
    eventAction: 'submit review',
    eventLabel: name + '::' + id,
    product_info: name + '::' + id,
  });
}

export function handleWriteReview(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'write_review',
    eventCategory: 'product detail page',
    eventAction: 'write review',
    eventLabel: name + '::' + id,
    product_info: name + '::' + id,
  });
}

// NAVIGATION MENUS
export function handleNavigationClick(area, label) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'menu_click',
    eventCategory: 'main menu navigation',
    eventAction: 'select::' + area,
    eventLabel: label,
    click_area: area,
    breadcrumb: label,
  });
}

// PRODUCT CLICK
export function productClickTagging(elem, context, idx) {
  const name = elem.currentTarget.getAttribute('data-gtm-name');
  const id = elem.currentTarget.getAttribute('data-gtm-ean');
  const category = elem.currentTarget.getAttribute('data-gtm-category');
  const list = elem.currentTarget.getAttribute('data-gtm-list');
  const position = idx;
  let pageCategory = '';
  if (context.route.templateName === 'ProductRoute') {
    pageCategory = 'product detail page';
  } else {
    pageCategory = context.route.fields.pageCategoryGTM.value;
  }

  window.dataLayer.push({
    event: 'productClick',
    event_name: 'select_item',
    eventAction: 'Product Click',
    eventCategory: 'Ecommerce',
    eventLabel: name + '::' + id,
    ecommerce: {
      actionField: {
        action: 'click',
        list: list,
      },

      products: [
        {
          name: name,
          brand: 'LPB',
          category: category,
          id: id,
          list: list,
          position: position,
        },
      ],
    },
  });
}